import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

import { mapGetters, mapMutations } from "vuex";
import Utility from "@/mixins/Utility.js";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import cleverTap from "@/mixins/clevertap.js";
import common from "@/mixins/common.js";

import { _clientKey } from "./../provider-config.js";

const FirebaseActions = {
  data() {
    return {
      filteredAvailabilities: [],
      pricemodel: null,
      availabilities: [],
    };
  },
  computed: {
    ...mapGetters(["subscriberid", "profileid", "availabilityList", "getFirebaseUserUID"]),
  },
  methods: {
    ...mapMutations(["setFirebaseUserUID"]),

    firebaseAnonymousLogin() {
      firebase
        .auth()
        .signInAnonymously()
        .then((data) => {
          this.firebaseStoreUser(data.user.uid);

          this.setFirebaseUserUID(data.user.uid);

          console.log("THE FIREBASE USER ID --> ", data.user.uid);
        });
    },

    firebaseStoreUser(uid) {
      let secretKey = _clientKey;

      secretKey = secretKey.substring(0, secretKey.length - 3);

      let path = `/${secretKey}`;

      const obj = {};

      obj[uid] = this.subscriberid;

      firebase
        .database()
        .ref(path)
        .update(obj)
        .then((data) => {
          console.log("LOGIN DATA SUCCESSFULLY WRITTEN --", data);
        });
    },

    firebaseRemoveUser() {
      let secretKey = _clientKey;

      secretKey = secretKey.substring(0, secretKey.length - 3);

      let path = `/${secretKey}`;

      return firebase
        .database()
        .ref(path)
        .child(this.getFirebaseUserUID)
        .remove()
        .then(() => {
          console.log("THE USER ID WAS REMOVED");
        })
        .catch((err) => {
          console.log("Remove KEY Error", err);
        });
    },

    fetchLikeDislikeCount(data) {
      return new Promise((resolve) => {
        let path = `content/${data.objectid}/${data.type}/count`;
        firebase
          .database()
          .ref(path)
          .on("value", (snapshot) => {
            let snapshotTemp = snapshot.val();
            if (data.type === "like") {
              this.likeCount = snapshot.val();
            } else {
              this.dislikeCount = snapshot.val();
            }
            resolve(snapshotTemp);
          });
      });
    },
    fetchContentInfoFromFireBase(content) {
      return new Promise((resolve) => {
        let pathContent = "content";
        let path = `/subscriber/${this.subscriberid}/${this.profileid}/${pathContent}/${content.objectid}`;
        firebase
          .database()
          .ref(path)
          .on("value", (snapshot) => {
            resolve(snapshot.val());
          });
      });
    },

    checkFireDataExists(path) {
      return new Promise((resolve) => {
        firebase
          .database()
          .ref(path)
          .once("value", (snapshot) => {
            // If value exists, return true else false
            resolve(snapshot.val() ? true : false);
          });
      });
    },
    updateContentCount(content, countData, clicked, changeData) {
      let path = `content/${content.objectid}`;
      let subscriberPath = `/subscriber/${this.subscriberid}/${this.profileid}/content/${content.objectid}`;

      // firebase.database().ref(path)
      this.checkFireDataExists(path).then((state) => {
        state ? updateCountData() : putCountData();
      });
      function putCountData() {
        let data = {
          like: {
            count: null,
          },
          dislike: {
            count: null,
          },
        };
        if (clicked == "LIKE") {
          data.like.count = 1;
          data.dislike.count = 0;
        } else if (clicked == "DISLIKE") {
          data.like.count = 0;
          data.dislike.count = 1;
        }
        firebase
          .database()
          .ref(path)
          .set(data);
      }

      function updateCountData() {
        let contentState = "";
        firebase
          .database()
          .ref(subscriberPath)
          .once("value", (snapshot) => {
            if (snapshot.val()) {
              contentState = snapshot.val().likestatus;
            }
          });
        let countObj = {};
        if (clicked === "LIKE") {
          countObj = {
            like: {
              count: countData + 1,
            },
          };
        } else if (clicked === "LIKE" && contentState === "DISLIKE" && changeData !== undefined) {
          countObj = {
            like: {
              count: countData + 1,
            },
            dislike: {
              count: changeData > 0 ? changeData - 1 : 0,
            },
          };
        } else if (clicked === "REMOVELIKE") {
          countObj = {
            like: {
              count: countData > 0 ? countData - 1 : 0,
            },
          };
        } else if (clicked === "DISLIKE") {
          countObj = {
            dislike: {
              count: countData + 1,
            },
          };
        } else if (clicked === "DISLIKE" && contentState === "LIKE" && changeData !== undefined) {
          countObj = {
            like: {
              count: changeData > 0 ? changeData - 1 : 0,
            },
            dislike: {
              count: countData + 1,
            },
          };
        } else if (clicked === "REMOVEDISLIKE") {
          countObj = {
            dislike: {
              count: countData > 0 ? countData - 1 : 0,
            },
          };
        }
        firebase
          .database()
          .ref(path)
          .update(countObj);
        // console.log("count data", countData);
      }
    },
    cleverTapUpdate(content, status) {
      //assigning availability list response.
      if (this.availabilityList !== undefined) {
        this.availabilities = this.availabilityList;
      }
      this.checkAvailabilityForClevertap(this.content);
      let data = {
        content_id: content.objectid,
        content_title: content.title,
        content_type: content.objecttype,
        content_genre: content.genre,
        content_language: content.contentlanguage[0],
        content_duration: content.duration,
        content_price: this.pricemodel === "FREE" ? "Free" : "Paid",
        rating: status,
      };

      //clevertap event
      this.ratedCleverTapEvent(data);

      //google analytics event
      this.ratedEvent(data);
    },
    updateLikeDislikeToFire(content, status) {
      if (!content || !status) return;
      let localDisplayLang = localStorage.getItem("setDisplayLanguageCode")
      localDisplayLang = localDisplayLang == 'hi' ? 'hin' : localDisplayLang;
      this.cleverTapUpdate(content, status);

      let objectid = content.objectid;
      // Define firebase base path
      let path = `/subscriber/${this.subscriberid}/${this.profileid}/content/`;
      if ("seriesid" in content && content.seriesid) {
        // let seriesid = content.seriesid;

        path += `${content.seriesid}/episodes/${content.objectid}`;
      } else {
        path += `${content.objectid}`;
      }
      // console.log("path", path);
      // Checks for data existance
      this.checkFireDataExists(path).then((state) => {
        // If data already exists, update data, else put data for first time
        // console.log("state", state);
        state ? updateData() : putData();
      });

      // For managing the scope
      // let self = this;
      let landscapeImage, portraitImage, squareImage;
      function putData() {
        let landscapeIndex = content.poster.findIndex((el) => {
          return el.postertype === "LANDSCAPE";
        });

        let portraitIndex = content.poster.findIndex((el) => {
          return el.postertype === "PORTRAIT";
        });

        let squareIndex = content.poster.findIndex((el) => {
          return el.postertype === "SQUARE";
        });

        if (landscapeIndex > -1) {
          landscapeImage = content.poster[landscapeIndex].filelist[2].filename;
          // console.log("landscapeImage", landscapeImage);
        }

        if (portraitIndex > -1) {
          portraitImage = content.poster[portraitIndex].filelist[2].filename;
          // console.log("portaritImage", portraitImage);
        }

        if (squareIndex > -1) {
          squareImage = content.poster[squareIndex].filelist[2].filename;
          // console.log("squareImage", squareImage);
        }

        let data = {
          objectid: content.objectid ? content.objectid : "",
          category: content.category ? content.category : "",
          contentstatus: "ACTIVE",
          title: content.title ? content.title : "",
          objecttype: content.objecttype ? content.objecttype : "",
          duration: content.duration ? parseInt(content.duration) : 0,
          poster: {
            landscape: landscapeImage ? landscapeImage : "",
            portrait: portraitImage ? portraitImage : "",
            square: squareImage ? squareImage : "",
          },
          likestatus: status,
          genre:content.genre ? content.genre : "" ,
          seasoncount:content.seasoncount ? content.seasoncount : 1,
          displaylanguage: localDisplayLang ? localDisplayLang : "",
        };
        if ("seriesid" in content && content.seriesid) {
          (data.seriesid = content.seriesid),
            (data.seasonnum = content.seasonnum),
            (data.episodenum = content.episodenum);
        }

        firebase
          .database()
          .ref(path)
          .update(data);

        //logging user action google analytics event.
        let GA = {
          action_name: status,
        };

        try {
          this.userActionEvent(GA);
        } catch (err) {}
      }
      function updateData() {
        let data = "";
        try {
          firebase
            .database()
            .ref(path)
            .once("value", (snapshot) => {
              if (snapshot.val()) {
                let previousState = snapshot.val().likestatus;
                let updatedStatus = "NONE";
                if (status === "LIKE") {
                  updatedStatus = previousState === "LIKE" ? "NONE" : previousState === "DISLIKE" ? "LIKE" : "LIKE";
                } else if (status === "DISLIKE") {
                  updatedStatus =
                    previousState === "LIKE" ? "DISLIKE" : previousState === "DISLIKE" ? "NONE" : "DISLIKE";
                } else if (status === "NONE") {
                  updatedStatus = status;
                }
                data = {
                  likestatus: updatedStatus,
                };
              }
            });

          firebase
            .database()
            .ref(path)
            .update(data);
        } catch (data) {
          console.log("error", data);
        }

        //logging user action google analytics event.
        let GA = {
          action_name: status,
        };
        try {
          this.userActionEvent(GA);
        } catch (err) {}
      }
    },
    updateContentWatchlist(content, status) {
      if (!content) return;

      let path = `/subscriber/${this.subscriberid}/${this.profileid}/content/${content.objectid}`;

      let localDisplayLang = localStorage.getItem("setDisplayLanguageCode")
      localDisplayLang = localDisplayLang == 'hi' ? 'hin' : localDisplayLang;

      const updatePromise = putContentWatchListData();

      let landscapeImage, portraitImage, squareImage;

      function putContentWatchListData() {
        let landscapeIndex = content.poster.findIndex((el) => {
          return el.postertype === "LANDSCAPE";
        });

        let portraitIndex = content.poster.findIndex((el) => {
          return el.postertype === "PORTRAIT";
        });

        let squareIndex = content.poster.findIndex((el) => {
          return el.postertype === "SQUARE";
        });

        if (landscapeIndex > -1) {
          landscapeImage = content.poster[landscapeIndex].filelist[1].filename;
        }

        if (portraitIndex > -1) {
          portraitImage = content.poster[portraitIndex].filelist[1].filename;
        }

        if (squareIndex > -1) {
          squareImage = content.poster[squareIndex].filelist[1].filename;
        }

        let data = {
          objectid: content.objectid ? content.objectid : "",
					objecttype: content.objecttype ? content.objecttype : "",
          category: content.category ? content.category : "",
          title: content.title ? content.title : "",
          updatedat: Math.floor(new Date().getTime() / 1000),
          poster: {
            landscape: landscapeImage ? landscapeImage : "",
            portrait: portraitImage ? portraitImage : "",
            square: squareImage ? squareImage : "",
          },
          genre: content.genre ? content.genre : "",
          contentstatus: "ACTIVE",
          duration: content.duration ? parseInt(content.duration) : 0,
          inwatchlist: status,
          // seasoncount:content.seasoncount ? content.seasoncount : "",
          displaylanguage: localDisplayLang ? localDisplayLang : "",
        };
				if (content.category === "TVSHOW" && content.seasoncount) {
					data.seasoncount = content.seasoncount;
        }

        return firebase
          .database()
          .ref(path)
          .update(data);
      }

      return updatePromise;
    },

    checkContinueWatchContents() {
      return new Promise((resolve) => {
        if (this.subscriberid || this.profileid) {
          let path = `/subscriber/${this.subscriberid}/${this.profileid}/content`;

          firebase
            .database()
            .ref(path)
            // .orderByChild("updatedat")
            .once("value", (snapshot) => {
              if (snapshot.val()) {
                let response = this.collectionsToArray(snapshot.val());
                this.continueWatchContent = response.filter((element) => {
                  return element.status === "INPROGRESS" && element.watchedduration !== null;
                });
                this.continueWatchContent = this.continueWatchContent.sort((a, b) => {
                  return b.updatedat - a.updatedat;
                });
                resolve(this.continueWatchContent);
                if (this.continueWatchContent.length > 0) {
                  return this.continueWatchContent;
                } else {
                  return false;
                }
              }
              resolve();
            });
        }
      });
    },

    // Update the firebase data and this is the main function
    updateContinueWatch(content, watchStatus, watchDuration) {
      if (!content) return;

      let localDisplayLang = localStorage.getItem("setDisplayLanguageCode")
      localDisplayLang = localDisplayLang == 'hi' ? 'hin' : localDisplayLang;
      const nextepisodeid = localStorage.getItem("nextepisodeid");
      // Common path for SERIES and MOVIES
      let path = `/subscriber/${this.subscriberid}/${this.profileid}/content/`;

      if ("seriesid" in content && content.seriesid) {
        // Concat episodes path to the  main path if the content is a series
        path += `${content.seriesid}/episodes/${content.objectid}`;
      } else {
        // Concat  only the object ID if it is a MOVIE
        path += `${content.objectid}`;
      }


      let landscapeImage, portraitImage, squareImage;

      return putContentContinueData();

      function putContentContinueData() {

        if (Array.isArray(content.poster)) {

        
          let landscapeIndex = content.poster.findIndex((el) => {
            return el.postertype === "LANDSCAPE";
          });

          let portraitIndex = content.poster.findIndex((el) => {
            return el.postertype === "PORTRAIT";
          });

          let squareIndex = content.poster.findIndex((el) => {
            return el.postertype === "SQUARE";
          });

          if (landscapeIndex > -1) {
            landscapeImage = content.poster[landscapeIndex].filelist[2].filename;
          }

          if (portraitIndex > -1) {
            portraitImage = content.poster[portraitIndex].filelist[2].filename;
          }

          if (squareIndex > -1) {
            squareImage = content.poster[squareIndex].filelist[2].filename;
          }
      } else {
        landscapeImage = content.poster.landscape;
        portraitImage = content.poster.portrait;
        squareImage = content.poster.square;
      }

        // The  main onbject, and the only object
        let data = {
          objectid: content.objectid ? content.objectid : "",
          objecttype: content.objecttype ? content.objecttype : "",
          category: content.category ? content.category : "",
          genre: content.genre ? content.genre : "",
          title: content.title ? content.title : "",
          updatedat: Math.floor(new Date().getTime() / 1000),
          poster: {
            landscape: landscapeImage ? landscapeImage : "",
            portrait: portraitImage ? portraitImage : "",
            square: squareImage ? squareImage : "",
          },
          status: watchStatus,
          contentstatus: "ACTIVE",
          duration: content.duration ? parseInt(content.duration) : 0,
          watchedduration: watchStatus === "INPROGRESS" ? watchDuration : null,
          displaylanguage: localDisplayLang ? localDisplayLang : "",
          
        };
        if ("seriesid" in content && content.seriesid) {
          data.seriesid = content.seriesid;
          data.seasonnum = content.seasonnum;
          data.episodenum = content.episodenum;
          data.nextepisodeid=nextepisodeid ? nextepisodeid : ""
        }

        return firebase
          .database()
          .ref(path)
          .update(data);
      }

      function updateContentContinueData() {
        let data = {
          status: watchStatus,
          watchedduration: watchStatus === "INPROGRESS" ? watchDuration : null,
          updatedat: Math.floor(new Date().getTime() / 1000),
        };

        firebase
          .database()
          .ref(path)
          .update(data);
      }
    },
    updateSeriesData(content) {
      if (!content) return;

      let path = `/subscriber/${this.subscriberid}/${this.profileid}/content/${content.objectid}`;

      this.checkFireDataExists(path).then((state) => {
        state ? updateContentContinueData() : putContentContinueData();
      });

      let landscapeImage, portraitImage, squareImage;

      function putContentContinueData() {
        let landscapeIndex = content.poster.findIndex((el) => {
          return el.postertype === "LANDSCAPE";
        });

        let portraitIndex = content.poster.findIndex((el) => {
          return el.postertype === "PORTRAIT";
        });

        let squareIndex = content.poster.findIndex((el) => {
          return el.postertype === "SQUARE";
        });

        if (landscapeIndex > -1) {
          landscapeImage = content.poster[landscapeIndex].filelist[2].filename;
        }

        if (portraitIndex > -1) {
          portraitImage = content.poster[portraitIndex].filelist[2].filename;
        }

        if (squareIndex > -1) {
          squareImage = content.poster[squareIndex].filelist[2].filename;
        }
        let data = {
          objectid: content.objectid ? content.objectid : "",
          category: content.category ? content.category : "",
          contentstatus: "ACTIVE",
          title: content.title ? content.title : "",
          objecttype: content.objecttype ? content.objecttype : "",
          updatedat: Math.floor(new Date().getTime() / 1000),
          poster: {
            landscape: landscapeImage ? landscapeImage : "",
            portrait: portraitImage ? portraitImage : "",
            square: squareImage ? squareImage : "",
          },
        };

        firebase
          .database()
          .ref(path)
          .set(data);
      }

      function updateContentContinueData() {
        let data = {
          updatedat: Math.floor(new Date().getTime() / 1000),
        };

        firebase
          .database()
          .ref(path)
          .update(data);
      }
    },

    removeFromContinueWatch(content) {
      let path = `/subscriber/${this.subscriberid}/${this.profileid}/content/`;

      if ("seriesid" in content && content.seriesid) {
        path += `${content.seriesid}/episodes/${content.objectid}`;
      } else {
        path += `${content.objectid}`;
      }

      firebase
        .database()
        .ref(path)
        .child("watchedduration")
        .remove();

      firebase
        .database()
        .ref(path)
        .child("status")
        .remove();

    },
    vLiveSDKContentLikeDislike(content, LikeCount, DislikeCount) {
      // If not able to get vLiveWebSdk instance, return
      if (!window.vLiveWebSdk) return;
      // Define the Analytics data
      let data = {
        EventCategory: "CONTENT",
        Event: "LikeDislike",
        ContentID: content.contentid,
        LikeCount: LikeCount,
        DislikeCount: DislikeCount,
      };
      // Push Data to SDK
      window.vLiveWebSdk.put(data);
    },
  },
  mixins: [Utility, googleAnalytics, cleverTap, common],
};

export default FirebaseActions;

import Vue from "vue";
import vueRouter from "vue-router";
import { routes } from "./routes";

Vue.use(vueRouter);

export const router = new vueRouter({
  routes,
  mode: "history"
});

// const DEFAULT_TITLE = 'Hello India';
// router.afterEach((to, from) => {
//     // Use next tick to handle router history correctly
//     // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
//     Vue.nextTick(() => {
//         document.title = to.meta.title || DEFAULT_TITLE;
//     });
// });
